<template>
  <div class="dashboard-container">
    <el-row :gutter="12" style="margin-top: 120px;">
      <el-carousel :interval="7000" arrow="always">
        <el-carousel-item v-for="item in items" :key="item">
          <h3>{{ item }}</h3>
        </el-carousel-item>
      </el-carousel>
    </el-row>
    <div v-if="register == false">
      <el-row :gutter="12">
        <el-col :span="24">
          <el-card shadow="always" class="margin-button-20">
          </el-card>
        </el-col>
      </el-row>
    </div>
    <!------
    <el-row justify="center">
      <el-col :span="20">
        <div class="graphic-container" id="graphd">
          <Highcharts></Highcharts>
        </div>
      </el-col>
    </el-row>
    ---->
  </div>
</template>

<script>
// import Highcharts from '@/components/Highcharts/Highcharts.vue'
import { mapGetters } from 'vuex'
import { getScopes } from '@/api/admin'

export default {
  /*
  components: {
    Highcharts
  },
  */
  data () {
    return {
      items: [],
      allScopes: [],
      register: true
    }
  },
  created () {
    this.getAllScopes()
  },
  computed: {
    ...mapGetters([
      'scopes'
    ])
  },
  methods: {
    setScopes () {
      console.log(this.allScopes)
      this.allScopes.forEach((item) => {
        if (this.scopes.includes(item.value)) {
          this.items.push(item.label)
        }
      })
    },
    getAllScopes () {
      getScopes()
        .then((response) => {
          this.allScopes = response.data
          this.setScopes()
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.dashboard {
  &-container {
    margin: 30px;
  }
  &-text {
    font-size: 30px;
    line-height: 46px;
  }
}
.el-carousel__item h3 {
    color: #475669;
    font-size: 40px;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
    text-align: center;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }
</style>
